import React, { useReducer } from 'react';
import SettingsContext from './settingsContext';
import settingsReducer from './settingsReducer';

import {
  isFunctionalCookieEnabled,
  isDarkModeCookieEnabled,
  isFormDataCookieEnabled,
} from '../../components/utils/checkCookies';

import { SET_DARK_MODE, REFRESH, SET_FORM_SAVE, SET_SETTINGS } from '../types'; // eslint-disable-next-line*/

import { DOMAIN } from '../../components/links';

import imagesDataJson from '../../../public/images/imgindex.json';
import imgMetaData from '../../../public/images/metadata.json';

const mergeImagesData = (imagesData, metaData) => {
  const mergedData = {};

  // Define the keys to separate the data
  const keys = [
    'Gartenarbeit',
    'Hausmeisterdienste',
    'Landschaftsbau',
    'Reparaturenservice',
    'Umzuege',
  ];

  keys.forEach((key) => {
    const basePath = `C:\\nhfm\\static\\images\\gallery\\${key}`;
    const thumbsPath = `${basePath}\\thumbs`;

    // Initialize the structure for each key
    mergedData[key] = {
      images: [],
      // thumbs: [],
    };

    // Process images
    if (imagesData[basePath]) {
      imagesData[basePath].forEach((image) => {
        if (image.fileName) {
          // Find the corresponding thumbnail
          const thumb = imagesData[thumbsPath]?.find(
            (thumb) => thumb.fileName === image.fileName
          );

          // Only process items with a valid fileName and also is isEnabled or not
          if (metaData[image.fileName]?.isEnabled) {
            mergedData[key].images.push({
              fileName: image.fileName,
              original: `${DOMAIN}/images/gallery/${key}/${image.fileName}`,
              thumbnail: thumb
                ? `${DOMAIN}/images/gallery/${key}/thumbs/${thumb.fileName}`
                : null,
              //isEnabled: metaData[image.fileName]?.isEnabled || false,
              description: metaData[image.fileName]?.description || '',
            });
          }
        }
      });
    }

    // // Process thumbs
    // if (imagesData[thumbsPath]) {
    //   imagesData[thumbsPath].forEach((thumb) => {
    //     if (thumb.fileName) {
    //       // Only process items with a valid fileName
    //       mergedData[key].thumbs.push({
    //         fileName: thumb.fileName,
    //         href: `${DOMAIN}/images/gallery/${key}/thumbs/${thumb.fileName}`,
    //       });
    //     }
    //   });
    // }
  });

  return mergedData;
};

// init state
const SettingsState = (props) => {
  let localSett = { darkMode: false, formSave: false }; // this is default setting
  if (isFunctionalCookieEnabled()) {
    // check if there are settings saved in a cookie, if not, use default above
    if (isDarkModeCookieEnabled()) {
      localSett = { ...localSett, darkMode: true };
    }
    if (isFormDataCookieEnabled()) {
      localSett = { ...localSett, formSave: true };
    }
  }
  const initialState = {
    darkMode: localSett.darkMode,
    formSave: localSett.formSave,
    imagesGallery: mergeImagesData(imagesDataJson, imgMetaData),
  };

  const [state, dispatch] = useReducer(settingsReducer, initialState);

  const setDarkMode = (booleanVal) => {
    dispatch({ type: SET_DARK_MODE, payload: booleanVal });
  };

  const setFormSave = (booleanVal) => {
    dispatch({ type: SET_FORM_SAVE, payload: booleanVal });
  };

  const setSettings = (obj) => {
    dispatch({ type: SET_SETTINGS, payload: obj });
  };

  const resetSettings = () => {
    dispatch({ type: REFRESH });
  };

  return (
    <SettingsContext.Provider
      value={{
        darkMode: state.darkMode,
        formSave: state.formSave,
        imagesGallery: state.imagesGallery,
        setDarkMode,
        setFormSave,
        setSettings,
        resetSettings,
      }}
    >
      {props.children}
    </SettingsContext.Provider>
  );
};

export default SettingsState;
