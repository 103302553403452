const IS_DEVELOPMENT_AT_HOME =
  process.env.GATSBY_IS_DEVELOPMENT_AT_HOME == 1 ? true : false;
// process.env.GATSBY_IS_DEVELOPMENT_AT_HOME == 1 ? true : false;
// if true, then use ip address as if I want to test also mobile device

const DOMAIN = IS_DEVELOPMENT_AT_HOME
    ? `http://192.168.178.46:8000`
    : `${process.env.GATSBY_DOMAIN}`,
  MAIL_API = IS_DEVELOPMENT_AT_HOME
    ? `http://192.168.178.46:3015`
    : `${process.env.GATSBY_MAIL_ENDPOINT}`,
  // IS_MAIL_API_USING_SSL = `${process.env.GATSBY_IS_MAIL_ENDPOINT_USING_SSL}`,
  // this is used for development. And in developent when I use SSL (not signed)
  // this also needs to be for production tested
  ABOUT = `/ueber`,
  CONTACT = `/kontakt`,
  SERVICES = `/leistungen`,
  GARTENPFLEGE = `${SERVICES}/gartenpflege`,
  HAUSMEISTERSERVICE = `${SERVICES}/hausmeisterservice`,
  REINIGUNGSSERVICE = `${SERVICES}/reinigungsservice`,
  REPARATURSERVICE = `${SERVICES}/reparaturservice`,
  WINTERDIENST = `${SERVICES}/winterdienst`,
  UMZUGSDIENST = `${SERVICES}/umzugsdienst`,
  DATA_PRIVACY = `/privacy`,
  IMPRESSUM = `/impressum`;

export {
  DOMAIN,
  ABOUT,
  CONTACT,
  SERVICES,
  MAIL_API,
  DATA_PRIVACY,
  IMPRESSUM,
  GARTENPFLEGE,
  HAUSMEISTERSERVICE,
  REINIGUNGSSERVICE,
  REPARATURSERVICE,
  WINTERDIENST,
  UMZUGSDIENST,
  // IS_MAIL_API_USING_SSL,
};
